import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default {

    getAllNiveaux(callback){

    axios.get(myConfig.api_url+`/api/v1/allNiveauB?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    addNiveau(args,callback){


        axios.post(myConfig.api_url+`/api/v1/addNiveauB?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    updateNiveau(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateNiveauB?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deleteNiveau(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteNiveauB?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}