<template>
  <span>
    <div class="recu">
      <button class="back-btn" @click="fermer()">Fermer</button>
      <button class="imp-btn" @click="toPdf()">Imprimer</button>
      <div style="margin:20px 0"></div>
      <div style="border:2px solid red;width:80%;margin:auto">
        <div class="page" id="page">
          <div class="marginbottom">
            <div style="padding:0 20px;">
              <div style="text-align:center">
                <img width="200" src="../../../assets/cropped-logo.png" alt="">
              </div>
              <div style="margin:20px 0;text-align:center">
                <h4>CENTRE {{centre}} , le : {{date}}</h4>
                <h3>Liste de présence Groupe {{groupe}} </h3>
              </div>
              <div style="text-align:left">
                <table class="ficheTable" border="1" >
                  <thead>
                    <tr>
                      <th>Nom et Prénom</th>
                      <th>Présence</th>
                      <th>Marque</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l,i of liste" :key="i">
                      <td style="width:70%;">{{l.name}}</td>
                      <td style="width:15%;"></td>
                      <td :style="l.marque==1?'width:15%;background-color:yellow;':l.marque==2?'width:15%;background-color:red;':''"></td>
                    </tr>
                    <tr v-if="listeInv.length!=0">
                      <th style="background-color:orange;text-align:center" colspan="3">les Invités</th>
                    </tr>
                    <tr v-if="listeInv.length!=0">
                      <th colspan="2">name</th>
                      <th colspan="1">telephone</th>
                    </tr>
                    <tr v-for="(m,i) of listeInv" :key="i">
                      <td colspan="2">{{m.name}}</td>
                      <td colspan="1">{{m.telephone}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>  
import moment from "moment"
let $ = window.$;
export default { 
  data() {
    return { 
      groupe:"",
      liste:[],
      listeInv:[],
      centre:localStorage.getItem('centreName'),
      date:moment().format('YYYY-MM-DD'),
    };
  },
  mounted() {
    if(this.$route.params.idgroupe==null){
      this.$router.push({ name: "Groupes" });
    }
    else{
      this.groupe = this.$route.params.groupe;
      this.liste = JSON.parse(this.$route.params.liste);
      this.listeInv = JSON.parse(this.$route.params.listeInv);
    }
  },
  methods: {
    toPdf() {
      const pdf = document.getElementById("page").innerHTML;
      let style = "<style>";
      style = style + ".page{padding: 70px 50px;}";
      style = style + ".ficheTable{width: 100%;border-collapse: collapse;}";
      style = style + "th,td{text-align: center; border: 1px solid black;padding: 8px 20px;font-size: 15px;}";
      style = style + ".hidden{display:none}";
      style = style + "</style>";
      const win = window.open("", "", "height=700,width=700");
      win.document.write("<html><head>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write(pdf);
      win.document.write("</body></html>");
      win.document.close();
      win.print();
    },
    fermer(){
      if(localStorage.getItem("role") != -1)
        this.$router.push({name:'Groupes'})
      else
        this.$router.push({name:'PGroupes'})
      
    },
  }
    
};
</script>

<style lang="scss">
.recu{
  width: 80%;
  padding-top: 20px;
  margin: auto;
  text-align: center;
  .imp-btn{
    color: white;
    background-color: rgb(13, 201, 13);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .back-btn{
    color: white;
    background-color: red;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .page{
    padding: 70px 50px;
  }
  .ficheTable{
    width: 100%;
    border-collapse: collapse;
    th,td{
      text-align: center;
      border: 1px solid black;
      padding: 8px 20px;
      font-size: 15px;
    }
  }
}
</style>
