<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Groupes</h4>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="groupes"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>N Gr</th>
                            <th>Matiere</th> 
                            <th>Niveau</th> 
                            <th>Cadence</th> 
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->

        <!-- modal Categories prof -->
        <div
          class="modal fade"
          id="showCatsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Categories</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowCats"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>designation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeCategories.length==0"><td colspan="1" align="center">Aucun periode trouve</td></tr>
                        <tr v-for="(d,i) of GroupeCategories" :key="i">
                          <td>{{d.designation}}</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowCats">
                  fermer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Categories -->

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal Horaires prof -->
        <div
          class="modal fade"
          id="showHorairesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Horaires</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowHoraires"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeHoraires.length==0"><td colspan="1" align="center">Aucun Horaire trouve</td></tr>
                        <tr v-for="(m,i) of GroupeHoraires" :key="i">
                          <td>{{weekDays[m['jour']] +' ' + m['heure-debut'] +':'+m['min-debut'] + ' - ' + m['heure-fin'] +':'+m['min-fin']}}</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowHoraires">
                  fermer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Horaires -->

        

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal Seances -->
        <div
          class="modal fade"
          id="showSeancesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Seances</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowSeances"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Heure debut</th>
                        <th>Heure fin</th>
                        <th>Nbr heures</th>
                        <th>Prof</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeSeances.length==0"><td colspan="5" align="center">Aucun Seance trouve</td></tr>
                        <tr v-for="(m,i) of GroupeSeances" :key="i">
                          <td>{{m.date}}</td>
                          <td>{{m.debut}}</td>
                          <td>{{m.fin}}</td>
                          <td>{{parseFloatToFixed1(m.nbr_heurs)}}</td>
                          <td>{{m.Prof}}</td>
                          <td><button class="waves-effect btn  btn-info" @click="showPresenceModal(m.idseance,m.modifiable)"> Presence </button></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowSeances">
                  fermer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Seances -->

        <!-- modal add Seances prof -->
        <div
          class="modal fade"
          id="AddSeancesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter Seance</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddSeancesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Date</label>
                    <div :class="v.formAddSeance.date.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      
                      <input
                        type="date"
                        class="form-control"
                        v-model="formAddSeance.date"
                      />
                    </div>
                    <div class="input-errors" v-for="error of v.formAddSeance.date.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">Prof</label>
                    <div :class="v.formAddSeance.idprof.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                      <select class="form-select" v-model="formAddSeance.idprof">
                        <option :value="null" disabled>Choisie </option> 
                        <option
                        :value="m['idprof']"
                          v-for="m in Profs"
                          :key="m['idprof']"
                          >{{m.nom + " " + m.prenom}}</option> 
                      </select>
                    </div>
                    <div class="input-errors" v-for="error of v.formAddSeance.idprof.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div> 
                  </div>
                </div>
                <div class="row">
                  <label class="form-label">Heure debut</label>
                  <div class="col-5">
                    <div class="form-group">
                      <div :class="v.formAddSeance.heure_debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        
                        <input
                          type="number"
                          min="0"
                          placeholder="Nombre d'heures..."
                          class="form-control"
                          v-model="formAddSeance.heure_debut"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.formAddSeance.heure_debut.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>H
                  <div class="col-5">
                    <div class="form-group">
                      <div :class="v.formAddSeance.min_debut.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        
                        <input
                          type="number"
                          min="0"
                          placeholder="Nombre d'heures..."
                          class="form-control"
                          v-model="formAddSeance.min_debut"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.formAddSeance.min_debut.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>MIN
                </div>
                <div class="row">
                  <label class="form-label">Heure fin</label>
                  <div class="col-5">
                    <div class="form-group">
                      <div :class="v.formAddSeance.heure_fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        
                        <input
                          type="number"
                          min="0"
                          placeholder="Nombre d'heures..."
                          class="form-control"
                          v-model="formAddSeance.heure_fin"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.formAddSeance.heure_fin.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>H
                  <div class="col-5">
                    <div class="form-group">
                      <div :class="v.formAddSeance.min_fin.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        
                        <input
                          type="number"
                          min="0"
                          placeholder="Nombre d'heures..."
                          class="form-control"
                          v-model="formAddSeance.min_fin"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.formAddSeance.min_fin.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>MIN
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddSeancesModal">
                  Non
                </button>
                <button @click="saveSeances" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add Seances prof -->

        <!-- modal Supprimer Seances prof -->
        <div
          class="modal fade"
          id="DeleteSeancesModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer Seance</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeleteSeancesModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer cette seance?</h4>
                <div class="row">
                <div class="col-12">
                  <div>
                    <strong class="form-label">seance : </strong>
                    {{formSeancesD.idseance}}
                  </div>
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteSeancesModal">
                  Non
                </button>
                <button @click="DeleteSeances" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer Seances prof -->

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal inscrits-->
        <div
          class="modal fade"
          id="showInscritsModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des Inscrits</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowInscrits"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Nom et prenom</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeInscrits.length==0"><td colspan="1" align="center">Aucun Inscrit trouve</td></tr>
                        <tr v-for="(m,i) of GroupeInscrits" :key="i">
                          <td>{{m.name}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-succes" style="background-color: #04a08b;border-color: #04a08b;color: #ffffff;">
                              <i @click="OpenFacture(m)" title="facture" style="cursor:pointer;" class="ti-save" aria-hidden="true"></i>
                            </button>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info" style="background-color: #00baff;border-color: #00baff;color: #ffffff;">
                              <i @click="OpenClient(m.idclient)" title="facture" style="cursor:pointer;" class="ti-user" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowInscrits">
                  fermer
                </button>
                <button @click="imprimerListe" type="button" class="btn btn-primary float-end">
                  Imprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal inscrits -->
        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal A Payer-->
        <div
          class="modal fade"
          id="showAPayerModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des restes A payer</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowAPayer"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Nom et prenom</th>
                        <th>Montant</th>
                        <th>date</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupeAPayer.length==0"><td colspan="3" align="center">Aucun Client trouve</td></tr>
                        <tr v-for="(m,i) of GroupeAPayer" :key="i">
                          <td>{{m.name}}</td>
                          <td>{{m.montant}}</td>
                          <td>{{m.date}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-succes" style="background-color: #04a08b;border-color: #04a08b;color: #ffffff;">
                              <i @click="OpenFacture(m)" title="facture" style="cursor:pointer;" class="ti-save" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowAPayer">
                  fermer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal A Payer -->

        <!---------------------------------------------------------------------------------------------------------------------------------->

        <!-- modal Presence-->
        <div
          class="modal fade"
          id="showPresenceModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste de Presence</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowPresence"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h5 class="modal-title" id="exampleModalLabel">Les personnes marqué devraient aller à la direction</h5>
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr>
                        <th>Nom et prenom</th>
                        <th>Presence</th>
                        <th>Marque</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="SeancePresence.length==0"><td colspan="2" align="center">Aucun Presence trouve</td></tr>
                        <tr v-for="(m,i) of SeancePresence" :key="i">
                          <td>{{m.name}}</td>
                          <td><input :disabled="!modifiable" @change="AjouterPresenceList(m,$event,'p')" style="position: unset;opacity: 1;width: 30px;height: 30px;" type="checkbox" :checked="m.present==0?false:true"></td>
                          <td :style="m.marque==1?'background-color:yellow;':m.marque==2?'background-color:red;':''"></td>
                          <td>
                            <select :disabled="!modifiable" @change="AjouterPresenceList(m,$event,'r')">
                              <option :value="null">Aucun</option>
                              <option :selected="m.remarque==1" :value="1" style="color:orange">Retard 30min</option>
                              <option :selected="m.remarque==2" :value="2" style="color:orange">Retard 60min</option>
                              <option :selected="m.remarque==3" :value="3" style="color:orange">Retard 90min</option>
                              <option :selected="m.remarque==4" :value="4" style="color:red">Quitter avant la fin de la séance 30min</option>
                              <option :selected="m.remarque==5" :value="5" style="color:red">Quitter avant la fin de la séance 60min</option>
                              <option :selected="m.remarque==6" :value="6" style="color:red">Quitter avant la fin de la séance 90min</option>
                            </select>
                          </td>
                        </tr>
                        <tr v-if="SeanceInvite.length!=0">
                          <th style="background-color:orange;text-align:center" colspan="4">les Invités</th>
                        </tr>
                        <tr v-if="SeanceInvite.length!=0">
                          <th colspan="3">name</th>
                        </tr>
                        <tr v-for="(m,i) of SeanceInvite" :key="i">
                          <td colspan="3">{{m.name}}</td>
                          <td>
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" >
                              <i @click="DeleteInviteModal(m.id)" title="Supprimer" style="cursor:pointer;" class="ti-trash" aria-hidden="true"></i>
                            </button>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowPresence">
                  fermer
                </button>
                <button v-if="modifiable" type="button" class="btn btn-primary float-end" id="addsalle" @click="imprimerListe">
                  Imprimer
                </button>
                <button v-if="modifiable" type="button" class="btn btn-primary float-end" id="addsalle" @click="updatePresence">
                  Enregistrer
                </button>
                <button v-if="modifiable" type="button" class="btn btn-primary float-end" id="addsalle" @click="AddInviteModal">
                  Ajouter invité
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Presence -->

        <!-- modal add invite -->
        <div
          class="modal fade"
          id="AddInviteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter invité</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeAddInviteModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label">Nom</label>
                      <div :class="v.formAddInv.nom.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        
                        <input
                          type="text"
                          class="form-control"
                          v-model="formAddInv.nom"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.formAddInv.nom.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label">Prenom</label>
                      <div :class="v.formAddInv.prenom.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        
                        <input
                          type="text"
                          class="form-control"
                          v-model="formAddInv.prenom"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.formAddInv.prenom.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label">telephone</label>
                      <div class="input-group mb-15">
                        <input
                          type="text"
                          class="form-control"
                          v-model="formAddInv.telephone"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeAddInviteModal">
                  Non
                </button>
                <button @click="saveInvite" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal add invite -->

        <!-- modal Supprimer invite  -->
        <div
          class="modal fade"
          id="DeleteInviteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer invité </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                   @click="closeDeleteInviteModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer invité?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteInviteModal">
                  Non
                </button>
                <button @click="DeleteInvite" type="button" class="btn btn-primary float-end" id="deleteprof">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer invite -->

        <!-- modal groupe Presence-->
        <div
          class="modal fade"
          id="showGroupePresenceModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style="max-width: 800px;" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste de Presence</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalshowGroupePresence"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div style="overflow-x: auto;max-height: 500px;">
                  <table
                    class="table table-bordered table-striped dataTable"
                  >
                    <thead>
                      <tr v-if="!GroupePresences[0]">
                        <th>Nom et prenom</th>
                      </tr>
                      <tr v-if="GroupePresences[0]">
                        <th>Nom et prenom</th>
                        <th v-for="g,i in GroupePresences[0].data" :key="i">{{i+1}}</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-if="GroupePresences.length==0"><td colspan="1" align="center">Aucun Presence trouve</td></tr>
                        <tr v-for="g,i in GroupePresences" :key="i">
                          <td>{{g.name}}</td>
                          <td v-for="p,j of g.data" :key="j" :style="p=='P'?'background-color:rgb(13, 201, 13)':p=='A'?'background-color:red':'background-color:gray'">
                            {{p=="A"||p=="P"?p:''}}
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalshowGroupePresence">
                  fermer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal groupe Presence -->
        <Facture v-if="openFac" :FI="formI" />
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import GroupeApi from "../../../api/groupe";
import AnneeApi from "../../../api/annees";
import matiereApi from "../../../api/matiere";
import cadenceApi from "../../../api/cadence";
import documentationApi from "../../../api/documentation";
import profApi from "../../../api/prof";
import salleApi from "../../../api/salle";
import categorieApi from "../../../api/categorie";
import horaireApi from "../../../api/horaire";
import CentreApi from "../../../api/centre";
import Facture from "../../../components/facture.vue";
import moment from "moment"

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
    Facture,
  },
  data() {
    return { 
      weekDays:['lundi','mardi','mercredi','jeudi','vendredi','samedi','dimanche'],
      loader:false,
      Groupes:[],  
      GroupeCategories:[],  
      GroupeHoraires:[],  
      GroupePeriodes:[],  
      GroupeSeances:[],  
      GroupePresences:[],  
      GroupeInscrits:[],
      GroupeAPayer:[],
      SeancePresence:[],
      SeanceInvite:[],
      Categories:[],  
      Horaires:[],  
      Periodes:[],
      Annees:[],  
      Matieres:[],
      Cadences:[], 
      Documentations:[], 
      Centres:[],
      Profs:[], 
      Salles:[], 
      selectedId:null,
      selectedSeance:null,
      modifiable:false,
      url:null,
      updatedPresences:[],
      formAddInv:{
        idseance:null,
        nom:'',
        prenom:'',
        telephone:'',
      },
      formUpdateInv:{
        id:null,
        idseance:null,
        nom:'',
        prenom:'',
        telephone:'',
      },
      formDeleteInv:{
        id:null,
      },
      formAddSeance:{
        date:moment().format('YYYY-MM-DD'),
        idprof:null,
        heure_debut:'',
        min_debut:'',
        heure_fin:'',
        min_fin:'',
      },
      formSeancesD:{
        idseance:null
      },
      formI:{ 
        nom:'',
        prenom:'', 
        idclient:'' ,
        idfacture:0,
        CategorieId:null,
      },
      openFac:false,
      selectedGroupeNom:"",
      
      filter:{
        periode:null,
        cat:null,
        cadence:null,
        mat:null,
      },
    };
  },
  validations () {
    return { 
      formAddSeance:{
        date:{ required },
        idprof:{ required },
        heure_debut:{ required },
        min_debut:{ required },
        heure_fin:{ required },
        min_fin:{ required },
      },
      formAddInv:{
        nom:{ required },
        prenom:{ required },
      },
      formUpdateInv:{
        nom:{ required },
        prenom:{ required },
      },
    }
  },
  async mounted() {
    this.datatable();
    await AnneeApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Annees = r.data; 
          }
        }
      });
      await matiereApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Matieres = r.data; 
          }
        }
      });
      await profApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Profs = r.data; 
          }
        }
      });
      await documentationApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Documentations = r.data; 
          }
        }
      });
      await salleApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Salles = r.data; 
          }
        }
      });
      await cadenceApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Cadences = r.data; 
          }
        }
      });
      await categorieApi.getAllCategories((r) => {
        if(r!=null){
          if(r.success==true){
            this.Categories = r.data; 
          }
        }
      });
      await horaireApi.getAll((r) => {
        if(r!=null){
          if(r.success==true){
            this.Horaires = r.data; 
          }
        }
      });
      CentreApi.getAll((r)=>{
        if(r!=null){
          this.Centres=r.data;
        }
      })
  },
  methods: {
    AjouterPresenceList(o,val,t){
      if(this.updatedPresences.map(v=>v.id).indexOf(o.idpresence)!=-1){
        if(t=="p"){
          this.updatedPresences[this.updatedPresences.map(v=>v.id).indexOf(o.idpresence)].presence = val.target.checked;
        }
        if(t=="r"){
          this.updatedPresences[this.updatedPresences.map(v=>v.id).indexOf(o.idpresence)].remarque = val.target.value;
        }
      }
      else{
        const obj = {
          id:o.idpresence,
          presence:o.present,
          marque:o.marque,
          remarque:o.remarque,
          heure:o.heure,
        };
        if(t=="p"){
          obj.presence = val.target.checked;
        }
        if(t=="r"){
          obj.remarque = val.target.value;
        }
        this.updatedPresences.push(obj)
      }
    },
    updatePresence(){
      if(this.updatedPresences.length==0){
        this.closeModalshowPresence();
      }
      for(const u of this.updatedPresences){
        GroupeApi.updatePresence({
          id:u.id, 
          present:u.presence,
          marque:u.marque,
          remarque:u.remarque,
          heure:u.heure,
        },(r) => { 
          if(r!=null)
          {
            if(r.success==true){
              this.ClearModal();
              this.closeModalshowPresence();
            }
          }
        });
      }
    },
    parseFloatToFixed1(v){
      return parseFloat(v).toFixed(1);
    },
    async loadData() {
      this.loader = true;
      await GroupeApi.getAll2((r) => {
        if(r!=null){
          if(r.success==true){
            this.Groupes = []; 
            for(const g of r.data){
              if(g['prof-par-defaut'] == localStorage.getItem('id') && g.idannee == localStorage.getItem('annee'))
              {
                  this.Groupes.push(g)
              }
            }
            var t = $("#groupes").DataTable();
            t.clear().rows.add(this.Groupes).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
      
    },
    filtrer(){
      this.loadData();
    },
    reinitialiser(){
      this.filter.cat = null;
      this.filter.mat = null;
      this.filter.periode = null;
      this.filter.cadence = null;
      this.loadData();
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#groupes").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          data: this.Groupes,
          columns: [
            { data: "idgroupe" },
            { data: "mat" },  
            { data: "niveau" },
            { data: "nbr-heures" },   
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowSeances"> Seances </button>',
            },
            {
              defaultContent:
                '<button class="waves-effect btn  btn-info btnShowHoraires"> Horaire </button>',
            },

          ],
        });
      });
      const thisVue = this;
      //for show Categories
      $("#groupes tbody ").on("click", ".btnShowCategories", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showCatsModal(data["idgroupe"]);
      });
      //for show Presences
      $("#groupes tbody ").on("click", ".btnShowPresences", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#showGroupePresenceModal").modal("show");
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        thisVue.GroupePresences = [];
        GroupeApi.getAllGroupeInscrits({idgroupe:data["idgroupe"]},(r) => {
          if(r!=null){
            if(r.success==true){
              const inscrits = r.data;
              GroupeApi.getGroupeSeances({idgroupe:data["idgroupe"]},(rr) => {
                if(rr!=null){
                  if(rr.success==true){
                    const hh = [];
                    const seances = rr.data;
                    for(const i of inscrits){
                      const row={name:i.name,idinscription:i.idinscription,data:[]};
                      for(const s of seances){
                        row.data.push(s.idseance)
                      }
                      hh.push(row);
                    }
                    GroupeApi.getGroupePresences({idgroupe:data["idgroupe"]},(rrr) => {
                      if(rrr!=null){
                        if(rrr.success==true){
                          for (const h of hh) {
                            for(const p of rrr.data){
                              if(h.idinscription == p.idinscription){
                                for(let i = 0 ; i < h.data.length ; i++){
                                  if(h.data[i]==p.idseance){
                                    if(p.present == 1){
                                      h.data[i]="P";
                                    }
                                    else{
                                      h.data[i]="A";
                                    }
                                  }
                                }
                              }
                            }
                          }
                          thisVue.GroupePresences=hh;
                          $("#globalLoader").removeClass("S");
                          $("#globalLoader").addClass("H");
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
        
      });
      //for show Horaires
      $("#groupes tbody ").on("click", ".btnShowHoraires", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showHorairesModal(data["idgroupe"]);
      });
      //for show Periodes
      $("#groupes tbody ").on("click", ".btnShowPeriodes", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showPeriodesModal(data["idgroupe"]);
      });

      //for show Inscrits
      $("#groupes tbody ").on("click", ".btnShowInscrits", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showInscritsModal(data["idgroupe"],"Gr N°" + data.idgroupe + " , " + data.niveau + " - " + data.mat);
      });

      //for show Seances
      $("#groupes tbody ").on("click", ".btnShowSeances", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formAddSeance.idprof=data["prof-par-defaut"];
        thisVue.showSeancesModal(data["idgroupe"]);
      });

      //for show APayer
      $("#groupes tbody ").on("click", ".btnShowAPayer", function () {
        var t = $("#groupes").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.showAPayerModal(data["idgroupe"]);
      });
      
    },
    //////////////////////////////////////////////
    async loadCatsData() {
      this.GroupeCategories = []; 
      await GroupeApi.getGroupeCategoriesByIdGroupe({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeCategories = r.data; 
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showCatsModal(id) {
      this.selectedId = id;
      $("#showCatsModal").modal("show");
      this.loadCatsData();
    },
    closeModalshowCats() {
      $("#showCatsModal").modal("hide");
    },
    ///////////////////////////////////////////////////
    async loadHorairesData() {
      this.GroupeHoraires = []; 
      await GroupeApi.getGroupeHorairesByIdGroupe({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeHoraires = r.data; 
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showHorairesModal(id) {
      this.selectedId = id;
      $("#showHorairesModal").modal("show");
      this.loadHorairesData();
    },
    closeModalshowHoraires() {
      $("#showHorairesModal").modal("hide");
    },
    ///////////////////////////////////////////////////
    async loadPeriodesData() {
      this.GroupePeriodes = []; 
      await GroupeApi.getGroupePeriodesByIdGroupe({id:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupePeriodes = r.data; 
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showPeriodesModal(id) {
      this.selectedId = id;
      $("#showPeriodesModal").modal("show");
      this.loadPeriodesData();
    },
    closeModalshowPeriodes() {
      $("#showPeriodesModal").modal("hide");
    },
    ///////////////////////////////////////////////////
    async loadSeancesData() {
      this.GroupeSeances = [];
      await GroupeApi.getGroupeSeances({idgroupe:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeSeances = [];
            const GroupeSeances = [];
            for(let i = 0 ; i < r.data.length ; i++){
              const v = r.data[i];
              if(r.data.length == i+1){
                v.modifiable = true;
                GroupeSeances.push(v);
              }
              else
                v.modifiable = false;
            }
            this.GroupeSeances = GroupeSeances.reverse();
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showSeancesModal(id) {
      this.selectedId = id;
      $("#showSeancesModal").modal("show");
      this.loadSeancesData();
    },
    closeModalshowSeances() {
      $("#showSeancesModal").modal("hide");
    },
    showDeleteSeancesModal(d) {
      this.formSeancesD.idseance = d['idseance'];
      $("#DeleteSeancesModal").modal("show");
    },
    closeDeleteSeancesModal() {
      $("#DeleteSeancesModal").modal("hide");
    },
    showAddSeancesModal() {
      $("#AddSeancesModal").modal("show");
    },
    closeAddSeancesModal() {
      $("#AddSeancesModal").modal("hide");
    },
    saveSeances(){ 
      if(this.v.formAddSeance.$invalid){//
        this.v.formAddSeance.$touch();//
      }//
      else{
      GroupeApi.getGroupeProfTarif({idgroupe:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            const tarif = r.tarif;
            GroupeApi.CreateSeance({
              date_reglement:null,   
              tarif:tarif,   
              date:this.formAddSeance.date,
              idgroupe:this.selectedId,
              idprof:this.formAddSeance.idprof,
              heure_debut:this.formAddSeance.heure_debut,
              min_debut:this.formAddSeance.min_debut,
              heure_fin:this.formAddSeance.heure_fin,
              min_fin:this.formAddSeance.min_fin,
            },(r) => {
              if(r!=null)
              {
                if(r.success==true){
                  this.loadSeancesData();
                  this.closeAddSeancesModal()
                }
                else{
                  console.log("error",r.msg)
                }
              }
            });
          }
        }
      });
      
    }
    },
    DeleteSeances(){
      console.log(this.formSeancesD.idseance)
      GroupeApi.deleteSeance({
        idseance:this.formSeancesD.idseance, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadSeancesData();
            this.closeDeleteSeancesModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    ///////////////////////////////////////////////////
    async loadInscritsData() {
      this.GroupeInscrits = [];
      console.log({idgroupe:this.selectedId,idperiode:this.filter.periode})
      await GroupeApi.getGroupeInscrits({idgroupe:this.selectedId,idperiode:this.filter.periode},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeInscrits = r.data;
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showInscritsModal(id,nom) {
      if(this.filter.periode==null){
        alert('Aucune periode a été sélectionné!');
      }
      else{
        this.selectedId = id;
        this.selectedGroupeNom = nom;
        $("#showInscritsModal").modal("show");
        this.loadInscritsData();
      }
    },
    closeModalshowInscrits() {
      $("#showInscritsModal").modal("hide");
    },
    imprimerListe(){
      const liste = JSON.stringify(this.SeancePresence);
      const listeInv = JSON.stringify(this.SeanceInvite);
      const data = {
        liste:liste,
        listeInv:listeInv,
        groupe:this.selectedGroupeNom,
        idgroupe:this.selectedId,
      };
      this.closeModalshowPresence();
      this.closeModalshowSeances();
      this.$router.push({ name: "List" , params: data });
    },
    OpenClient(id){
      window.open('/admin/clients/'+id, '_blank');
    },
    ///////////////////////////////////////////////////
    async loadAPayerData() {
      this.GroupeAPayer = [];
      await GroupeApi.getGroupeResterAPayer({idgroupe:this.selectedId},(r) => {
        if(r!=null){
          if(r.success==true){
            this.GroupeAPayer = r.data;
            console.log(r.data)
          }
          else{
            console.log('gg')
          }
        }
        else{
          console.log('gg')
        }
      });
    },
    showAPayerModal(id) {
      this.selectedId = id;
      $("#showAPayerModal").modal("show");
      this.loadAPayerData();
    },
    closeModalshowAPayer() {
      $("#showAPayerModal").modal("hide");
    },
    OpenFacture(d){
      this.openFac = false;
      setTimeout( ()=> {
        this.formI.nom = d.nom;
      this.formI.prenom = d.prenom;
      this.formI.idclient = d.idclient;
      this.formI.idfacture = d.idfacture;
      this.formI.CategorieId = d.CategorieId;
        this.openFac = true;
      },500)
    },
    ///////////////////////////////////////////////////
    async loadPresenceData() {
      this.SeancePresence = [];
      this.SeanceInvite = [];
      await GroupeApi.getSeancePresences({idseance:this.selectedSeance},async(r) => {
        if(r!=null){
          if(r.success==true){
            this.SeancePresence = r.data;
            await GroupeApi.getSeanceInvites({idseance:this.selectedSeance},(rr) => {
              if(rr!=null){
                if(rr.success==true){
                  this.SeanceInvite = rr.data;
                }
              }
            });
          }
        }
      });
    },
    AddInviteModal(){
      this.formAddInv.idseance = this.selectedSeance;
      this.formAddInv.nom = '';
      this.formAddInv.prenom = '';
      this.formAddInv.telephone = '';
      $("#AddInviteModal").modal("show");
    },
    closeAddInviteModal(){
      $("#AddInviteModal").modal("hide");
    },
    saveInvite(){ 
      if(this.v.formAddInv.$invalid){//
        this.v.formAddInv.$touch();//
      }//
      else{
        GroupeApi.createInvites({
          nom:this.formAddInv.nom,
          prenom:this.formAddInv.prenom,
          seance_idseance:this.formAddInv.idseance,
          telephone:this.formAddInv.telephone,
        },(r) => {
          if(r!=null){
            if(r.success==true){
              this.loadPresenceData();
              this.closeAddInviteModal()
            }
          }
        });
      
      }
    },
    DeleteInviteModal(id) {
      this.formDeleteInv.id = id;
      $("#DeleteInviteModal").modal("show");
    },
    closeDeleteInviteModal() {
      $("#DeleteInviteModal").modal("hide");
    },
    DeleteInvite(){
      GroupeApi.deleteInvites({
        id:this.formDeleteInv.id, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadPresenceData();
            this.closeDeleteInviteModal();
           }
           else{
             console.log("error",r.msg)
           }
         }
      });
    },
    showPresenceModal(id,modifiable) {
      this.modifiable = false;
      this.selectedSeance = id;
      this.modifiable = modifiable;
      this.updatedPresences = [];
      $("#showPresenceModal").modal("show");
      this.loadPresenceData();
    },
    closeModalshowPresence() {
      $("#showPresenceModal").modal("hide");
    },
    closeModalshowGroupePresence() {
      $("#showGroupePresenceModal").modal("hide");
    },
    ///////////////////////////////////////////////////
    ClearModal(){},
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
