<template>
  <span>
    <div class="recu">
      <button class="back-btn" @click="fermer()">Fermer</button>
      <button class="imp-btn" @click="toPdf()">Imprimer</button>
      <div style="margin:20px 0"></div>
      <div style="border:2px solid red;width:80%;margin:auto">
        <div class="page" id="page">
          <div class="marginbottom" style="display:flex;">
            <div style="padding:0 20px;width:50%;border-right:2px dashed black;">
              <div style="text-align:center">
                <img width="200" src="../../../assets/cropped-logo.png" alt="">
              </div>
              <div style="margin:20px 0;text-align:center">
                <h4>CENTRE {{data.centre}}, le : {{date}}</h4>
                <h3>Facture N°{{data.idfacture}}</h3>
              </div>
              <div style="text-align:left">
                <h4>Client: N°{{data.idclient}}, {{data.nom}}</h4>
                <table class="ficheTable" border="1" >
                  <thead>
                    <tr>
                      <th>Libellé</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Frais d'inscription</td>
                      <td>{{data.frais_inscription}} dhs</td>
                    </tr>
                    <tr v-for="ins,i of inscriptions" :key="i">
                      <td>
                        {{ins.matiere + " - " + ins.nbr_heures + "h/sem à partir du " + ins.date }}
                        <br>
                        {{ins.horaire}}
                      </td>
                      <td>{{ins.montant}} dhs</td>
                    </tr>
                  </tbody>
                </table>
                <h4>Total facturé: {{data.Total}} dhs</h4>
                <h5>Remise: {{data.remise?data.remise:0}} dhs</h5>
                <h5>Total payé: {{data.total_payer}} dhs</h5>
                <h3>Reste à payer: {{data.total_reste_a_paye}} dhs</h3>
              </div>
            </div>
            <div style="padding:0 20px;width:50%;">
              <div>
                <img width="200" src="../../../assets/cropped-logo.png" alt="">
              </div>
              <div style="margin:20px 0">
                <h4>CENTRE {{data.centre}}, le : {{date}}</h4>
                <h3>Facture N°{{data.idfacture}}</h3>
              </div>
              <div style="text-align:left">
                <h4>Client: N°{{data.idclient}}, {{data.nom}}</h4>
                <table class="ficheTable" border="1" >
                  <thead>
                    <tr>
                      <th>Libellé</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Frais d'inscription</td>
                      <td>{{data.frais_inscription}} dhs</td>
                    </tr>
                    <tr v-for="ins,i of inscriptions" :key="i">
                      <td>
                        {{ins.matiere + " - " + ins.nbr_heures + "h/sem à partir du " + ins.date }}
                        <br>
                        {{ins.horaire}}
                      </td>
                      <td>{{ins.montant}} dhs</td>
                    </tr>
                  </tbody>
                </table>
                <h4>Total facturé: {{data.Total}} dhs</h4>
                <h5>Remise: {{data.remise?data.remise:0}} dhs</h5>
                <h5>Total payé: {{data.total_payer}} dhs</h5>
                <h3>Reste à payer: {{data.total_reste_a_paye}} dhs</h3>
              </div>
            </div>
          </div>
          <div style="display:flex;margin-top:50px">
            <div style="padding:0 20px;width:50%;border-right:2px dashed black;">
              <h3 style="text-align:center">Détail des paiements</h3>
              <table class="ficheTable" border="1" >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Montant</th>
                    <th>Mode de paiement</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="p,i of paiements" :key="i">
                    <td>{{p.date}} </td>
                    <td>{{p.montant}} dhs</td>
                    <td>{{p.type}}</td>
                  </tr>
                </tbody>
              </table>
              <br>
              <h3 style="text-align:center">Détail du reste à payer</h3>
              <table class="ficheTable" border="1" >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Montant</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r,i of rester_a_payer" :key="i">
                    <td>{{r.date}} </td>
                    <td>{{r.montant}} dhs</td>
                  </tr>
                </tbody>
              </table>
              <br>
              <h5><span style="color:red">NB:</span>  En aucun cas les paiements et les avances de paiement ne seront remboursés.</h5>
            </div>
            <div style="padding:0 20px;width:50%;">
              <h3 style="text-align:center">Détail des paiements</h3>
              <table class="ficheTable" border="1" >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Montant</th>
                    <th>Mode de paiement</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="p,i of paiements" :key="i">
                    <td>{{p.date}} </td>
                    <td>{{p.montant}} dhs</td>
                    <td>{{p.type}}</td>
                  </tr>
                </tbody>
              </table>
              <br>
              <h3 style="text-align:center">Détail du reste à payer</h3>
              <table class="ficheTable" border="1" >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Montant</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r,i of rester_a_payer" :key="i">
                    <td>{{r.date}} </td>
                    <td>{{r.montant}} dhs</td>
                  </tr>
                </tbody>
              </table>
              <br>
              <h5><span style="color:red">NB:</span>  En aucun cas les paiements et les avances de paiement ne seront remboursés.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>  
import moment from "moment"
let $ = window.$;
export default { 
  data() {
    return { 
      data:{},
      paiements:[],
      inscriptions:[],
      rester_a_payer:[],
      date:moment().format('YYYY-MM-DD'),
    };
  },
  mounted() {
    if(this.$route.params.idfacture==null){
      this.$router.push({ name: "Clients" });
    }
    else{
      this.data = this.$route.params;
      this.paiements = JSON.parse(this.$route.params.paiement);
      this.inscriptions = JSON.parse(this.$route.params.inscription);
      this.rester_a_payer = JSON.parse(this.$route.params.reste_a_paye);
    }
  },
  methods: {
    toPdf() {
      const pdf = document.getElementById("page").innerHTML;
      let style = "<style>";
      style = style + ".page{padding: 70px 50px;}";
      style = style + ".ficheTable{width: 100%;border-collapse: collapse;}";
      style = style + "th,td{text-align: center; border: 1px solid black;padding: 8px 20px;font-size: 15px;}";
      style = style + ".hidden{display:none}";
      style = style + ".marginbottom{margin-bottom:700px}";
      style = style + "</style>";
      const win = window.open("", "", "height=700,width=700");
      win.document.write("<html><head>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write(pdf);
      win.document.write("</body></html>");
      win.document.close();
      win.print();
    },
    fermer(){
      this.$router.push({name:'Clients'})
    },
  }
    
};
</script>

<style lang="scss">
.recu{
  width: 80%;
  padding-top: 20px;
  margin: auto;
  text-align: center;
  .imp-btn{
    color: white;
    background-color: rgb(13, 201, 13);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .back-btn{
    color: white;
    background-color: red;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .page{
    padding: 70px 50px;
  }
  .ficheTable{
    width: 100%;
    border-collapse: collapse;
    th,td{
      text-align: center;
      border: 1px solid black;
      padding: 8px 20px;
      font-size: 15px;
    }
  }
}
</style>
