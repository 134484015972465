<template>
  <span>
    <div class="recu">
      <button class="back-btn" @click="fermer()">Fermer</button>
      <button class="imp-btn" @click="toPdf()">Imprimer</button>
      <div style="margin:20px 0"></div>
      <div style="border:2px solid red;width:80%;margin:auto">
        <div class="page" id="page">
          <div class="marginbottom">
            <div style="padding:0 20px;">
              <div style="text-align:center">
                <img width="200" src="../../../assets/cropped-logo.png" alt="">
              </div>
              <div style="margin:20px 0;text-align:center">
                <h4>CENTRE {{centre}} , le : {{date}}</h4>
                <h3>Fiche de paiement de Prof : {{Prof}} </h3>
              </div>
              <div style="text-align:left">
                <h3>Mois : <span v-for="m,i of mois" :key="i"> - {{m}}</span> </h3>
                <table class="ficheTable" border="1" >
                  <thead>
                    <tr>
                      <th>Groupe</th>
                      <th>Date séance</th>
                      <th>Horaire</th>
                      <th>Nbr heures</th>
                      <th>tarif</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l,i of liste" :key="i">
                      <td style="width:70%;">{{l.groupe}}</td>
                      <td style="width:70%;">{{l.date}}</td>
                      <td style="width:70%;">{{l.horaire}}</td>
                      <td style="width:70%;">{{l.nbr_heurs}}</td>
                      <td style="width:70%;">{{l.tarif}}</td>
                      <td style="width:70%;">{{l.montant}}</td>
                    </tr>
                  </tbody>
                </table>
                <h3 v-if="forfait>0">Montant Total des séances : {{total}} dhs </h3>
                <h3 v-if="forfait>0">Montant forfait : {{forfait}} dhs </h3>
                <h3>Total à régler : {{total + forfait}} dhs </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>  
import moment from "moment"
let $ = window.$;
export default { 
  data() {
    return { 
      mois:[],
      Prof:"",
      total:"",
      forfait:"",
      liste:[],
      centre:localStorage.getItem('centreName'),
      date:moment().format('YYYY-MM-DD'),
    };
  },
  mounted() {
    if(!this.$route.params.liste){
      this.$router.push({ name: "Seances" });
    }
    else{
      this.Prof = this.$route.params.Prof;
      this.total = parseInt(this.$route.params.total);
      this.forfait = parseInt(this.$route.params.forfeit);
      this.liste = JSON.parse(this.$route.params.liste);
      const mois = JSON.parse(this.$route.params.mois);
      const moisStrings = ['janvier','février','mars','avril','mai','juin','juillet','août','septembre','octobre','novembre','décembre'];
      
        console.log(mois)
      for(const m of mois){
        console.log(m)
        this.mois.push(moisStrings[m])
      }
    }
  },
  methods: {
    toPdf() {
      const pdf = document.getElementById("page").innerHTML;
      let style = "<style>";
      style = style + ".page{padding: 70px 50px;}";
      style = style + ".ficheTable{width: 100%;border-collapse: collapse;}";
      style = style + "th,td{text-align: center; border: 1px solid black;padding: 8px 20px;font-size: 15px;}";
      style = style + ".hidden{display:none}";
      style = style + "</style>";
      const win = window.open("", "", "height=700,width=700");
      win.document.write("<html><head>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write(pdf);
      win.document.write("</body></html>");
      win.document.close();
      win.print();
    },
    fermer(){
      this.$router.push({name:'Seances'})
      
    },
  }
    
};
</script>

<style lang="scss">
.recu{
  width: 80%;
  padding-top: 20px;
  margin: auto;
  text-align: center;
  .imp-btn{
    color: white;
    background-color: rgb(13, 201, 13);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .back-btn{
    color: white;
    background-color: red;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .page{
    padding: 70px 50px;
  }
  .ficheTable{
    width: 100%;
    border-collapse: collapse;
    th,td{
      text-align: center;
      border: 1px solid black;
      padding: 8px 20px;
      font-size: 15px;
    }
  }
}
</style>
